var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "m-0 text-subtitle-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("lbl_residue")) + " ")
            ])
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _vm.isModeCreate
                ? _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 6 } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                          ]),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("lbl_choose"),
                                "allow-clear": "",
                                disabled: _vm.isDisabled,
                                "dropdown-match-select-width": false
                              },
                              on: { change: _vm.onChangeSelect },
                              model: {
                                value: _vm.form.batchConsume,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "batchConsume", $$v)
                                },
                                expression: "form.batchConsume"
                              }
                            },
                            _vm._l(_vm.dtOpt.consumeBatch, function(item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.value,
                                  attrs: { value: item.value }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(item.key || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(item.key || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 6 } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                          ]),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("lbl_choose"),
                                "allow-clear": "",
                                disabled: _vm.isDisabled,
                                "dropdown-match-select-width": false,
                                loading: _vm.loading.code
                              },
                              on: {
                                popupScroll: _vm.popupScrollCode,
                                change: _vm.onChangeSelect
                              },
                              model: {
                                value: _vm.form.productCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "productCode", $$v)
                                },
                                expression: "form.productCode"
                              }
                            },
                            _vm._l(_vm.dtOpt.productCode, function(item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.key,
                                  attrs: { value: item.value },
                                  on: {
                                    click: function($event) {
                                      return _vm.onselectProduct(
                                        "code",
                                        item.meta
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(item.key || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(item.key || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 6 } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                          ]),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("lbl_choose"),
                                "allow-clear": "",
                                disabled: _vm.isDisabled,
                                "dropdown-match-select-width": false,
                                loading: _vm.loading.name
                              },
                              on: {
                                popupScroll: _vm.popupScrollName,
                                change: _vm.onChangeSelect
                              },
                              model: {
                                value: _vm.form.productName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "productName", $$v)
                                },
                                expression: "form.productName"
                              }
                            },
                            _vm._l(_vm.dtOpt.productName, function(item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.key,
                                  attrs: { value: item.value },
                                  on: {
                                    click: function($event) {
                                      return _vm.onselectProduct(
                                        "name",
                                        item.meta
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(item.key || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(item.key || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("a-col", { attrs: { sm: 24, md: 12, lg: 6 } }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("lbl_qty")))]),
                        _c(
                          "div",
                          [
                            _c(
                              "a-space",
                              [
                                _c("a-input-number", {
                                  attrs: {
                                    placeholder: _vm.$t("lbl_type_here"),
                                    formatter: _vm.formatterNumber,
                                    parser: _vm.reverseFormatNumber,
                                    precision: _vm.DECIMAL_PLACES_QTY,
                                    disabled: _vm.isDisabled,
                                    min: 0
                                  },
                                  on: { change: _vm.onChangeSelect },
                                  model: {
                                    value: _vm.form.qty,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "qty", _vm._n($$v))
                                    },
                                    expression: "form.qty"
                                  }
                                }),
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "dashed",
                                      disabled: _vm.isDisabled
                                    },
                                    on: { click: _vm.showModalScale }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_scale")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 6 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("lbl_uom")))]),
                          _c("CSelectUomConv", {
                            attrs: {
                              "prop-product-id": _vm.form.productId,
                              "is-disabled": _vm.isDisabled
                            },
                            on: { "on-select": _vm.onchangeUom },
                            model: {
                              value: _vm.form.uomId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "uomId", $$v)
                              },
                              expression: "form.uomId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isModeCreate
                ? _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "plus",
                                block: "",
                                disabled: _vm.validateResidue || _vm.isDisabled
                              },
                              on: { click: _vm.addResidue }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                type: "danger",
                                icon: "delete",
                                block: "",
                                disabled:
                                  !(
                                    !!_vm.form.productId ||
                                    !!_vm.form.qty ||
                                    !!_vm.form.uomId
                                  ) || _vm.isDisabled
                              },
                              on: { click: _vm.clearForm }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_reset_form")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isModeCreate ? _c("a-divider") : _vm._e(),
              _vm.residue.length
                ? _c(
                    "a-row",
                    { attrs: { gutter: [16, 16], type: "flex" } },
                    _vm._l(_vm.residue, function(item, j) {
                      return _c(
                        "a-col",
                        { key: item.key, attrs: { sm: 24, md: 12, xl: 8 } },
                        [
                          _c(
                            "a-card",
                            {
                              attrs: {
                                title: j + 1 + ". " + item.productCode,
                                size: "small"
                              }
                            },
                            [
                              _c("a-icon", {
                                attrs: {
                                  slot: "extra",
                                  type: "delete",
                                  theme: "twoTone",
                                  "two-tone-color": "#f5222d"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteResidue(item)
                                  }
                                },
                                slot: "extra"
                              }),
                              _c(
                                "div",
                                { staticClass: "p-3" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_product_code")) + ":"
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(item.productCode || "-"))
                                  ]),
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_product_name")) + ":"
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(item.productName || "-"))
                                  ]),
                                  _c("h4", [
                                    _vm._v(_vm._s(_vm.$t("lbl_qty")) + ":")
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._f("toDecimalQty")(item.qty))
                                    )
                                  ]),
                                  _c("h4", [
                                    _vm._v(_vm._s(_vm.$t("lbl_uom")) + ":")
                                  ]),
                                  _c("p", [_vm._v(_vm._s(item.uom || "-"))]),
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lbl_batch_number_consume")
                                      ) + ":"
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(item.batchConsume || "-"))
                                  ]),
                                  _vm.isModeView
                                    ? [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_batch_number")) +
                                              ":"
                                          )
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.batchNumber || "-") +
                                              ": "
                                          )
                                        ])
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [_c("a-col", [_c("a-empty")], 1)],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.modal.scale.show
        ? _c("CScale", {
            on: { "on-save": _vm.onScaleSave },
            model: {
              value: _vm.modal.scale.show,
              callback: function($$v) {
                _vm.$set(_vm.modal.scale, "show", $$v)
              },
              expression: "modal.scale.show"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }