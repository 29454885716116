

















































































































































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@service/product.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { ResponseDetailProduct, ResponseListProduct, ResponseProduct } from "@interface/product.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { JobCostingMeatroomProduceResidueDetail } from "@/models/interface-v2/job-costing-meatroom.interface";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";
import { mapMutations, mapState } from "vuex";
import { initFormResidue } from "@/store/resource/job-costing-meatroom.resource";

export interface IResidue {
  productCode: string | undefined;
  productId: string | undefined;
  productName: string | undefined;
  qty: number;
  uomId: string | undefined;
  uom: string;
  batchNumber: string;
  batchId: string;
  batchConsume?: string
  key?: number
}

export default Vue.extend({
  name: "JobCostingResidue",
  components: {
    CScale: () => import("@/components/shared/scale/CScale.vue"),
  },
  mixins: [
    MNotificationVue
  ],
  props: {
    propDetailDocument: {
      type: Array as () => JobCostingMeatroomProduceResidueDetail[],
      required: false,
      default: []
    },
    propConsumeProduct: {
      type: Array as () => any[],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      dtOpt: {
        productCode: [] as IOption[],
        productName: [] as IOption[],
        uom: [] as IOption[],
        consumeBatch: [] as IOption[],
      },
      form: {
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: "",
        qty: 0,
        uomId: undefined as string | undefined,
        uom: "",
        batchConsume: undefined as string | undefined
      },
      dtList: {
        product: {} as ResponseListProduct,
        productCode: {} as ResponseListProduct,
        productName: {} as ResponseListProduct,
      },
      residue: [] as IResidue[],
      loading: {
        uom: false,
        code: false,
        name: false,
      },
      queryParamsCode: {
        limit: 10,
        page: 0,
        search: ""
      },
      queryParamsName: {
        limit: 10,
        page: 0,
        search: ""
      },
      modal: {
        scale: {
          show: false,
          data: {} as any
        }
      }
    };
  },
  computed: {
    ...mapState({
      storeResiduePlaceholder: (store: any) => store.jobCostingMeatroomStore.placeholder,
    }),
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isDisabled(): boolean {
      return this.disabled;
    },
    validateResidue(): boolean {
      return !this.form.productId || !this.form.qty || this.form.qty < 0 || !this.form.uomId || !this.form.batchConsume;
    },
  },
  watch: {
    propDetailDocument: {
      deep: true,
      handler(newval) {
        if (newval) {
          this.viewDetail();
        }
      }
    },
    propConsumeProduct: {
      deep: true,
      immediate: true,
      handler(newval) {
        if (newval) {
          this.fetchListProduct();
          this.generateListBatchConsume();
        }
      }
    },
  },
  created() {
    if (this.isModeView) {
      this.viewDetail();
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    ...mapMutations({
      setPlaceholderFormResidue: "jobCostingMeatroomStore/SET_PLACEHOLDER_FORM_RESIDUE",
    }),
    showModalScale(): void {
      this.modal.scale.show = true;
    },
    onScaleSave({ value }): void {
      this.form.qty = value;
    },
    /**
     * generate options batch from each consume
     * used by residue
     */
    generateListBatchConsume(): void {
      if (!this.propConsumeProduct.length) return;
      this.dtOpt.consumeBatch = [];
      this.propConsumeProduct.forEach(x => {
        x.batchNumbers.forEach(y => {
          if (y.batchNumber) {
            this.dtOpt.consumeBatch.push({
              key: y.batchNumber,
              value: y.batchNumber,
            });
          }
        });
      });
    },
    getListProduct(params: RequestQueryParamsModel): Promise<ResponseListProduct> {
      return productService.listProduct(params);
    },
    getDetailProduct(id: string): Promise<ResponseDetailProduct> {
      return productService.detailProduct(id);
    },
    async findProduct(productId: string): Promise<void> {
      try {
        this.loading.uom = true;
        const res = await this.getDetailProduct(productId);
        this.fillUom(res);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.uom = false;
      }
    },
    fillUom(product: ResponseDetailProduct): void {
      if (product.uomConversions && product.uomConversions.length) {
        this.dtOpt.uom = product.uomConversions.map(x => {
          return { key: x.unitUom, value: x.unitUomId };
        });
      }
    },
    onchangeUom(): void {
      this.form.uom = this.dtOpt.uom.find(x => x.value === this.form.uomId)?.key || "";
      this.onChangeSelect();
    },
    async fetchListProduct(): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 10,
          search: "code~*#R"
        };
        let searchBy: string[] = [];
        searchBy = this.propConsumeProduct.map((x) => {
          return `code~${x.productCode.split("#")[0]}#R`;
        });
        param.search = searchBy.join("_OR_");
        this.queryParamsCode.search = param.search;
        this.queryParamsName.search = param.search;
        const res = await this.getListProduct(param);
        this.dtOpt.productCode = res.data.map(x => { return { key: x.code, value: x.code, meta: x }; });
        this.dtOpt.productName = res.data.map(x => { return { key: x.name, value: x.name, meta: x }; });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onselectProduct(source: "code" | "name", meta: ResponseProduct): void {
      if (source === "code") {
        this.form.productName = meta.name;
      } else {
        this.form.productCode = meta.code;
      }
      this.form.productId = meta.id;
      this.findProduct(this.form.productId);
    },
    addResidue(): void {
      const { residue } = this;
      const newResidue: IResidue = {
        ...this.form,
        batchId: "",
        batchNumber: "",
        key: residue.length,
      };

      this.residue = [...residue, newResidue];
      this.emitData();
      this.clearForm();

      // validation on the same batch number of consume product
      // const found = residue.find(x => x.batchConsume === newResidue.batchConsume);
      // if (found) {
      //   this.showNotifWarning("notif_error_duplicate_data");
      // } else {
      //   this.residue = [...residue, newResidue];
      //   this.emitData();
      //   this.clearForm();
      // }
    },
    clearForm(): void {
      this.form = {
        productCode: undefined,
        productName: undefined,
        productId: "",
        uom: "",
        uomId: undefined,
        qty: 0,
        batchConsume: undefined
      };
      this.setPlaceholderFormResidue(initFormResidue());
    },
    deleteResidue(record: IResidue): void {
      if (this.isDisabled) return;
      const { residue } = this;
      this.residue = residue.filter(x => x.key !== record.key);
      this.emitData();
    },
    emitData(): void {
      this.$emit("on-data-change", { data: this.residue });
    },
    viewDetail(): void {
      this.residue = this.propDetailDocument.map((x, i) => {
        return {...x, key: i};
      });
    },
    popupScrollCode(e): void {
      if ((this.dtList.productCode.totalPages - 1) === this.dtList.productCode.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParamsCode.page += 1;
        this.getListProductCode(this.queryParamsCode);
      }
    },
    popupScrollName(e): void {
      if ((this.dtList.productName.totalPages - 1) === this.dtList.productName.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParamsName.page += 1;
        this.getListProductName(this.queryParamsName);
      }
    },
    async getListProductCode(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.code = true;
        const res = await this.getListProduct(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.code, value: x.code, meta: x }));
        this.dtOpt.productCode = [...dtOpt.productCode, ...opts];
        this.dtList.productCode = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.code = false;
      }
    },
    async getListProductName(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.name = true;
        const res = await this.getListProduct(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.name, value: x.name, meta: x }));
        this.dtOpt.productName = [...dtOpt.productName, ...opts];
        this.dtList.productName = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.name = false;
      }
    },
    onChangeSelect(): void {
      // set value into residue store
      this.setPlaceholderFormResidue(this.form);
    },
  }
});

